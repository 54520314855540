import {
    Avatar, Box, Container, HStack, Image, Stack, Text, useBreakpointValue, useColorModeValue, Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    ButtonGroup,
    IconButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    FormControl,
    Input,
    FormLabel,
    Center,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { FiEdit, FiEye, FiTrash } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { getSpecifiedDoc, getDocInCollection } from '../../../../Api/firebase/getDoc';
import { db } from '../../../../Configs/firebase';
import { currencyFormat } from '../../../../Utils/currencyFormat';

function AppBarAdminCourseDetail() {
    const params = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [course, setCourse] = useState("");
    const [modalBody, setModalBody] = useState();

    const getCourse = async () => {
        const course = await getSpecifiedDoc('courses', params.id)
        console.log(course)
        setCourse(course)
    };

    const handleModal = (type, x) => {
        console.log(type, x, "modalData")
        const data = () => {
            switch (type) {
                case 'detailLesson':

                    // check if video is youtube
                    const youtubeRegex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+$/;
                    const checkYoutubeVideo = youtubeRegex.test(x.lessonVideo);
                    const data = {data:x, isYoutube: checkYoutubeVideo, videoYoutube: x.lessonVideo.split('=')[1]};
                    return (
                        <DetailLesson data={data} />
                    );

                case 'addLesson':
                    return (
                        <AddLesson data={x} />
                    );

                case 'editLesson':
                    return (
                        <EditLesson data={x} />
                    );
                case 'deleteLesson':
                    return (
                        <DeleteLesson data={x} />
                    );

                case 'detailSection':
                    return (
                        <DetailSection data={x} />
                    );

                case 'addSection':
                    return (
                        <AddSection data={x} />
                    );

                case 'editSection':
                    return (
                        <EditSection data={x} />
                    );
                case 'deleteSection':
                    return (
                        <DeleteSection data={x} />
                    );

                default:
                    return <h1>No project match</h1>;
            }
        };
        setModalBody(data);
        onOpen();
    };

    const DetailSection = (data) => {
        console.log(data, "data")
        const x = data.data;
        return (
            <>
                <ModalHeader>{x.sectionTitle}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Text fontSize="sm" color="gray.500">
                        {x.sectionDescription}
                    </Text>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </>
        )
    };

    const AddSection = () => {

        const [sectionId, setSectionId] = useState(`section${course.sections.length + 1}`);
        const [title, setTitle] = useState("");
        const [description, setDescription] = useState("");

        const handleAddSection = async () => {
            const newSection = {
                sectionId: sectionId,
                sectionTitle: title,
                sectionDescription: description,
                lessons: [{
                    isCompleted: [],
                    lessonId: `lesson1`,
                    lessonTitle: "Lesson 1",
                    lessonDescription: "Lesson 1 Description",
                    lessonVideo: "https://www.youtube.com/watch?v=7sDY4m8KNLc",
                }]
            }
            const newCourse = {
                ...course,
                sections: [...course.sections, newSection]
            }
            await setDoc(doc(db, "courses", params.id), newCourse);

            onClose();
            getCourse();
        };

        return (
            <>
                <ModalHeader>Add Section</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Stack spacing="10px">
                        <FormControl id="title" isDisabled>
                            <FormLabel fontSize="sm">Section ID</FormLabel>
                            <Input type="text" placeholder="Title" value={sectionId} />
                        </FormControl>

                        <FormControl id="title">
                            <FormLabel fontSize="sm">Title</FormLabel>
                            <Input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </FormControl>

                        <FormControl id="title">
                            <FormLabel fontSize="sm">Description</FormLabel>
                            <Input type="text" placeholder="Title" value={description} onChange={(e) => setDescription(e.target.value)} />
                        </FormControl>
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                    <Button colorScheme="blue" ml={3} onClick={() => handleAddSection()}>
                        Save
                    </Button>
                </ModalFooter>
            </>
        )
    };

    const EditSection = (data) => {
        console.log(data, "data")
        const x = data.data;

        const [title, setTitle] = useState(x.sectionTitle);
        const [description, setDescription] = useState(x.sectionDescription);

        const handleEditSection = async () => {
            console.log(title, description, "editSection")

            const selectedSection = course.sections.filter((item) => item.sectionId === x.sectionId);
            console.log(selectedSection, "selectedSection")

            const newSection = {
                sectionId: x.sectionId,
                sectionTitle: title,
                sectionDescription: description,
                lessons: selectedSection[0].lessons
            }

            const newSections = course.sections.map((item) => {
                if (item.sectionId === x.sectionId) {
                    return newSection;
                } else {
                    return item;
                }
            });

            console.log(newSections, "newSections")

            const newCourse = {
                ...course,
                sections: newSections
            }

            console.log(newCourse, "newCourse")

            // updateCourse(newCourse)
            const submit = await setDoc(doc(db, "courses", params.id), newCourse);
            console.log(submit, "submit")

            onClose();
            getCourse();
        };

        return (
            <>
                <ModalHeader>Edit Section</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Stack spacing="10px">
                        <FormControl id="title">
                            <FormLabel fontSize="sm">Title</FormLabel>
                            <Input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </FormControl>

                        <FormControl id="title">
                            <FormLabel fontSize="sm">Description</FormLabel>
                            <Input type="text" placeholder="Title" value={description} onChange={(e) => setDescription(e.target.value)} />
                        </FormControl>
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                    <Button colorScheme="blue" ml={3} onClick={() => handleEditSection()}>
                        Save
                    </Button>
                </ModalFooter>
            </>
        )
    };

    const DeleteSection = (data) => {
        const x = data.data;
        const handleDeleteSection = async () => {
            const newSections = course.sections.splice(x.sectionId, 1);
            console.log(newSections, "deleted Section")

            await setDoc(doc(db, "courses", params.id), course);
            onClose();
        };

        return (
            <>
                <ModalHeader>Delete Section</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Text fontSize="sm" color="gray.500">
                        Are you sure you want to delete this section?
                    </Text>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                    <Button colorScheme="red" ml={3} onClick={() => handleDeleteSection()}>
                        Delete
                    </Button>
                </ModalFooter>
            </>
        )
    };

    const DetailLesson = (data) => {
        const x = data.data.data;
        return (
            <>
                <ModalHeader>{x.lessonTitle}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Stack spacing={10} w={"full"}>
                        <Center maxW={"100%"} h={"100%"}>
                            <iframe
                                src={data.data.isYoutube ? `https://www.youtube.com/embed/${data.data.videoYoutube}` : `https://customer-m4s6qyxbn871sysu.cloudflarestream.com/${x.lessonVideo}/iframe`}
                                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                                allowfullscreen="true"
                            ></iframe>
                            
                        </Center>
                        <Text fontSize="sm" color="gray.500" textAlign={"center"}>
                            {x.lessonDescription}
                        </Text>
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </>
        )
    };

    const AddLesson = (data) => {
        const x = data.data;

        const [title, setTitle] = useState("");
        const [description, setDescription] = useState("");
        const [video, setVideo] = useState("");

        const handleAddLesson = async () => {
            const newLesson = {
                isCompleted: [],
                lessonId: `lesson${course.sections[x.index].lessons.length + 1}`,
                lessonTitle: title,
                lessonDescription: description,
                lessonVideo: video
            }
            const newSection = {
                ...course.sections[x.index],
                lessons: [...course.sections[x.index].lessons, newLesson]
            }
            const newSections = course.sections.map((item) => {
                if (item.sectionId === x.sectionId) {
                    return newSection;
                } else {
                    return item;
                }
            });
            const newCourse = {
                ...course,
                sections: newSections
            }
            await setDoc(doc(db, "courses", params.id), newCourse);
            onClose();
            getCourse();
        };

        return (
            <>
                <ModalHeader>Add Lesson</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Stack spacing="10px">
                        <FormControl id="lessonId" isDisabled>
                            <FormLabel fontSize="sm">Lesson ID</FormLabel>
                            <Input type="text" placeholder="Title" value={`lesson${course.sections[x.index].lessons.length + 1}`} />
                        </FormControl>

                        <FormControl id="title">
                            <FormLabel fontSize="sm">Title</FormLabel>
                            <Input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </FormControl>

                        <FormControl id="title">
                            <FormLabel fontSize="sm">Description</FormLabel>
                            <Input type="text" placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
                        </FormControl>

                        <FormControl id="title">
                            <FormLabel fontSize="sm">Video</FormLabel>
                            <Input type="text" placeholder="Video" value={video} onChange={(e) => setVideo(e.target.value)} />
                        </FormControl>
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                    <Button colorScheme="blue" ml={3} onClick={() => handleAddLesson()}>
                        Save
                    </Button>
                </ModalFooter>
            </>
        )
    };

    const EditLesson = (data) => {
        const x = data.data;
        console.log(x, "data")

        const [title, setTitle] = useState(x.lessonTitle);
        const [description, setDescription] = useState(x.lessonDescription);
        const [video, setVideo] = useState(x.lessonVideo);

        const handleEditLesson = async () => {
            const newLesson = {
                ...x,
                // isCompleted: [],
                lessonTitle: title,
                lessonDescription: description,
                lessonVideo: video
            }
            const newSection = {
                ...course.sections[x.sectionIndex],
                lessons: course.sections[x.sectionIndex].lessons.map((item) => {
                    if (item.lessonId === x.lessonId) {
                        return newLesson;
                    } else {
                        return item;
                    }
                })
            }
            const newSections = course.sections.map((item) => {
                if (item.sectionId === x.sectionId) {
                    return newSection;
                } else {
                    return item;
                }
            });
            const newCourse = {
                ...course,
                sections: newSections
            }
            await setDoc(doc(db, "courses", params.id), newCourse);
            onClose();
            getCourse();
        };

        return (
            <>
                <ModalHeader>Edit Lesson</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Stack spacing="10px">
                        <FormControl id="lessonId" isDisabled>
                            <FormLabel fontSize="sm">Lesson ID</FormLabel>
                            <Input type="text" placeholder="Title" value={x.lessonId} />
                        </FormControl>

                        <FormControl id="title">
                            <FormLabel fontSize="sm">Title</FormLabel>
                            <Input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </FormControl>

                        <FormControl id="title">
                            <FormLabel fontSize="sm">Description</FormLabel>
                            <Input type="text" placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
                        </FormControl>

                        <FormControl id="title">
                            <FormLabel fontSize="sm">Video</FormLabel>
                            <Input type="text" placeholder="Video" value={video} onChange={(e) => setVideo(e.target.value)} />
                        </FormControl>
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                    <Button colorScheme="blue" ml={3} onClick={() => handleEditLesson()}>
                        Save
                    </Button>
                </ModalFooter>
            </>
        )
    };

    const DeleteLesson = (data) => {
        console.log(data, "data")
        const x = data.data;

        const selectedSection = course.sections[x.sectionId];
        console.log(selectedSection, "selectedSection")

        const handleDeleteLesson = async () => {

            const removeLesson = course.sections[x.sectionId].lessons.splice(x.lessonId, 1);
            console.log(removeLesson, "removeLesson")

            // updateCourse(newCourse)
            const submit = await setDoc(doc(db, "courses", params.id), { ...course });
            console.log(submit, "submit")

            onClose();
        };

        return (
            <>
                <ModalHeader>Delete Lesson</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Text fontSize="sm" color="gray.500">
                        Are you sure you want to delete this lesson?
                    </Text>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                    <Button colorScheme="blue" ml={3} onClick={() => handleDeleteLesson()}>
                        Delete
                    </Button>
                </ModalFooter>
            </>
        )
    };

    useEffect(() => {
        getCourse();
    }, []);
    return (
        <Container
            py={{
                base: '4',
                md: '8',
            }}
            px={{
                base: '0',
                md: 8,
            }}
            maxW="container.xl"
        >
            <Box
                bg="bg-surface"
                boxShadow={{
                    base: 'none',
                    md: useColorModeValue('sm', 'sm-dark'),
                }}
                borderRadius={useBreakpointValue({
                    base: 'none',
                    md: 'lg',
                })}
                w={"100%"}
                p="4"
            >
                <HStack spacing="5" w={"100%"} mb={10}>
                    <Box w="50%">
                        <Image src={course.imageUrl} alt="Segun Adebayo" rounded={5} />
                    </Box>

                    <Stack w="50%" spacing={3} pl={3}>
                        <Text fontSize="2xl" fontWeight="bold" color="on-surface">
                            {course.title}
                        </Text>

                        <Text fontSize="md" fontWeight="medium" color="on-surface">
                            {course.description}
                        </Text>

                        <Text fontSize="md" fontWeight="medium" color="on-surface">
                            {currencyFormat(course.price)}
                        </Text>

                        <HStack spacing="5">
                            <Avatar size="lg" name="Segun Adebayo" src={course.insctructorImage} />
                            <Text fontSize="md" fontWeight="medium" color="on-surface">
                                {course.instructor}
                            </Text>
                        </HStack>
                    </Stack>
                </HStack>

                <Box >
                    <Text fontSize="lg" fontWeight="bold" mb={2}>
                        Course Contents
                    </Text>

                    <Box mt={2}>
                        <Accordion defaultIndex={[0]} allowToggle>
                            {course.sections && course.sections.map((item, index) => (
                                <>
                                    <AccordionItem key={index}>
                                        <HStack spacing="5" w={"100%"} p={2}>
                                            <AccordionButton>
                                                <Box flex='1' textAlign='left' alignItems={"center"} fontWeight={"bold"}>
                                                    {index + 1}. {item.sectionTitle}
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>

                                            <ButtonGroup size="md" isAttached variant="outline">
                                                <IconButton aria-label="Detail" icon={<FiEye />} onClick={() => handleModal("detailSection", item)} />
                                                <IconButton aria-label="Edit" icon={<FiEdit />} onClick={() => handleModal("editSection", item)} />
                                                {item.lessons.length === 0 && <IconButton aria-label="Delete" icon={<FiTrash />} onClick={() => handleModal("deleteSection", { sectionId: index })} />}
                                            </ButtonGroup>
                                        </HStack>
                                        <AccordionPanel pb={4}>
                                            <Accordion allowToggle>
                                                {item.lessons && item.lessons.map((lesson, index2) => (
                                                    <AccordionItem key={index2}>
                                                        <HStack spacing="5" justifyContent={"space-between"} p={2}>
                                                            <Box flex='1' textAlign='left' alignItems={"center"} p={3}>
                                                                <Text fontSize="md" fontWeight="normal" _hover={{
                                                                    textDecoration: "none",
                                                                    color: "blue.500",
                                                                    fontWeight: "semibold"
                                                                }}>
                                                                    {index + 1 + "." + (index2 + 1)}  {lesson.lessonTitle}
                                                                </Text>
                                                            </Box>

                                                            <ButtonGroup size="md" isAttached variant="outline">
                                                                <IconButton aria-label="Detail" icon={<FiEye />} onClick={() => handleModal("detailLesson", lesson)} />
                                                                <IconButton aria-label="Edit" icon={<FiEdit />} onClick={() => handleModal("editLesson", { ...lesson, sectionIndex: index, sectionId: item.sectionId, lessonIndex: index2 })} />
                                                                <IconButton aria-label="Delete" icon={<FiTrash />} onClick={() => handleModal("deleteLesson", { sectionId: index, lessonId: index2 })} />
                                                            </ButtonGroup>
                                                        </HStack>
                                                        <AccordionPanel pb={4}>
                                                            {lesson.description}
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                ))}
                                                <AccordionPanel>
                                                    <Box flex='1' textAlign='left' alignItems={"center"} p={3}>
                                                        <Text
                                                            fontSize="md"
                                                            fontWeight="semibold"
                                                            _hover={{
                                                                textDecoration: "none",
                                                                color: "blue.500",
                                                                fontWeight: "bold"
                                                            }}
                                                            onClick={() => handleModal("addLesson", { sectionId: item.sectionId, index: index })}
                                                        >
                                                            + Add Lesson
                                                        </Text>
                                                    </Box>
                                                </AccordionPanel>
                                            </Accordion>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </>
                            ))}
                            <AccordionItem>
                                <Box flex='1' textAlign='left' alignItems={"center"} p={3}>
                                    <Text
                                        fontSize="md"
                                        fontWeight="semibold"
                                        _hover={{
                                            textDecoration: "none",
                                            color: "blue.500",
                                            fontWeight: "bold"
                                        }}
                                        onClick={() => handleModal("addSection")}
                                    >
                                        + Add Section
                                    </Text>
                                </Box>
                            </AccordionItem>
                        </Accordion>

                    </Box>
                </Box>
            </Box>

            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    {modalBody}
                </ModalContent>
            </Modal>
        </Container>
    )
}

export default AppBarAdminCourseDetail
import {
    Box,
    Button,
    Container,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    HStack,
    Input,
    Stack,
    Text,
    useBreakpointValue,
    useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../Configs/firebase";
import { Link, useNavigate } from "react-router-dom";

import { Logo } from "../Components/AppComponents/LogoComponent";
import { GoogleIcon } from "../Components/AppComponents/ProviderIconsApp";
import { doc, setDoc } from "firebase/firestore";
import { SignupUser } from "../Contexts/action";
import { useAuthDispatch } from "../Contexts";
import KtLogoComponent from "../Components/AppComponents/KtLogoComponent";

function SignUpPage() {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmationPassword, setConfirmationPassword] = useState("");

    let navigate = useNavigate();
    const dispatch = useAuthDispatch()
    const toast = useToast();


    const onHandleRegister = async () => {
        let payload = {
            email,
            password,
            name,
        }
        try {
            let response = await SignupUser(dispatch, payload);
            console.log(response, "login response");

            if (response === undefined) {
                toast({
                    title: "Login Failed",
                    description: "check your email and password",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });

            } else if (response.uid !== undefined) {
                navigate('/dashboard')
            }
        } catch (error) {
            console.log(error)
            toast({
                title: error,
                description: "check your email and password",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    return (
        <Container maxW="md" py={{ base: "12", md: "24" }}>
            <Stack spacing="8">
                <Stack spacing="6" align="center">
                    <Box w="400px" alignSelf="center">
                        <KtLogoComponent />
                    </Box>
                    <Stack spacing="3" textAlign="center">
                        <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>
                            Create an account
                        </Heading>
                        <Text color="muted">Start making your dreams come true</Text>
                    </Stack>
                </Stack>
                <Stack spacing="6">
                    <Stack spacing="5">
                        <FormControl isRequired>
                            <FormLabel htmlFor="email">Full Name</FormLabel>
                            <Input
                                id="name"
                                type="name"
                                onChange={(e) => setName(e.target.value)}
                            />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <Input
                                id="email"
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel htmlFor="password">Password</FormLabel>
                            <Input
                                id="password"
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <FormHelperText color="muted">
                                At least 8 characters long
                            </FormHelperText>
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel htmlFor="password">Confirm Password</FormLabel>
                            <Input
                                id="password"
                                type="password"
                                onChange={(e) => setConfirmationPassword(e.target.value)}
                            />
                            <FormHelperText color="muted">
                                At least 8 characters long
                            </FormHelperText>
                        </FormControl>
                    </Stack>
                    <Stack spacing="4">
                        <Button colorScheme={"blue"} onClick={() => onHandleRegister()}>
                            Create Account
                        </Button>
                    </Stack>
                </Stack>
                <HStack justify="center" spacing="1">
                    <Text fontSize="sm" color="muted">
                        Already have an account?
                    </Text>
                    <Link to="/login">
                        <Button variant="link" colorScheme="blue" size="sm">
                            Log in
                        </Button>
                    </Link>
                </HStack>
            </Stack>
        </Container>
    );
}

export default SignUpPage;

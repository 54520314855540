import {
    Box,
    Container,
    Flex,
    Text,
    useBreakpointValue,
} from "@chakra-ui/react";
import * as React from "react";
import AppBarFooter from "../AppBars/AppBarFooter";
import { Navbar } from "../AppBars/AppBarNavigation";
import { Sidebar } from "../AppBars/AppBarSide";

function MainLayout() {
    const isDesktop = useBreakpointValue({
        base: false,
        lg: true,
    });
    const AppLayout = (Content) => {
        return (
            <Flex height={"full"}>
                {/* {isDesktop ? (
                    <Box height="full" overflowY="auto" justifyContent="flex-end">
                        <Sidebar />
                    </Box>
                ) : null} */}
                <Box
                    direction={{
                        base: "row",
                        lg: "column",
                    }}
                    bg="bg-canvas"
                    flex="1"
                >
                    <Navbar />
                    {/* {isDesktop ? null : <Navbar />} */}
                    <Content />
                    <AppBarFooter />
                </Box>
            </Flex>
        );
    };

    return { AppLayout };
}

export default MainLayout;
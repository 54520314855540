// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDta80B-qHYAVQr4C7M_6deoPo-dGmBADo",
  authDomain: "kelastambahan-frontend.firebaseapp.com",
  projectId: "kelastambahan-frontend",
  storageBucket: "kelastambahan-frontend.appspot.com",
  messagingSenderId: "796876365142",
  appId: "1:796876365142:web:47c9707bfd344274504af7",
  measurementId: "G-LJLBVY6M8G",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore(app);

export { auth, db, app };

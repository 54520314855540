import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Container,
    Heading,
    HStack,
    SimpleGrid,
    Skeleton,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
} from "@chakra-ui/react";
import { FiDownloadCloud } from "react-icons/fi";
import AppCoursesCard from "../../AppComponents/AppCoursesCard";
import { Link } from "react-router-dom";
import { getDocInCollection } from "../../../Api/firebase/getDoc";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../Configs/firebase";

function AppBarCourses() {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(false);
    const property = [{
        imageUrl: 'https://bit.ly/2Z4KKcF',
        imageAlt: 'Rear view of modern home with pool',
        title: 'Javascript 1',
        formattedPrice: 'Rp 300.000',
        reviewCount: 34,
        rating: 4,
        type: 'Programming',
    }, {
        imageUrl: 'https://bit.ly/2Z4KKcF',
        imageAlt: 'Rear view of modern home with pool',
        title: 'Javascript 2',
        formattedPrice: 'Rp 300.000',
        reviewCount: 34,
        rating: 4,
        type: 'Programming',
    },
    {
        imageUrl: 'https://bit.ly/2Z4KKcF',
        imageAlt: 'Rear view of modern home with pool',
        title: 'Javascript 2',
        formattedPrice: 'Rp 300.000',
        reviewCount: 34,
        rating: 4,
        type: 'Programming',
    },
    {
        imageUrl: 'https://bit.ly/2Z4KKcF',
        imageAlt: 'Rear view of modern home with pool',
        title: 'Javascript 2',
        formattedPrice: 'Rp 300.000',
        reviewCount: 34,
        rating: 4,
        type: 'Programming',
    }];

    const getCourse = async () => {
        setLoading(true);

        const q = query(collection(db, "courses"), where("status", "==", "published"));
        const querySnapshot = await getDocs(q);
        const docs = [];

        querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
        });
        console.log(docs, "this is docs");

        setCourses(docs);
        setLoading(false);
    }

    const Card = (props) => (
        <Box
            minH="150px"
            bg="#024BAC"
            boxShadow={useColorModeValue("sm", "sm-dark")}
            borderRadius="lg"
            {...props}
        />
    );

    const CardSkeleton = () => {
        return (
            <>
                <Skeleton height='sm' rounded={"md"} />
                <Skeleton height='sm' rounded={"md"} />
                <Skeleton height='sm' rounded={"md"} />
                <Skeleton height='sm' rounded={"md"} />
                <Skeleton height='sm' rounded={"md"} />
                <Skeleton height='sm' rounded={"md"} />
                <Skeleton height='sm' rounded={"md"} />
                <Skeleton height='sm' rounded={"md"} />

            </>
        )
    }

    useEffect(() => {
        getCourse();
    }, []);

    return (
        <Container maxW="container.xl" py="8" flex="1" mb={10}>
            <Stack
                spacing={{
                    base: "8",
                    lg: "6",
                }}
            >
                <Card />

                <Box >
                    <HStack spacing="4" mt={5} mb={5}>
                        <Text fontSize="lg" fontWeight="bold">
                            Courses
                        </Text>
                    </HStack>
                    <SimpleGrid
                        columns={{
                            base: 1,
                            md: 4,
                        }}
                        gap="6"
                    >
                        {loading === false ? (courses.map((course) => (
                            <AppCoursesCard property={course} key={course.id} />
                        ))) : (<CardSkeleton />)}
                    </SimpleGrid>
                </Box>
            </Stack>
        </Container >
    );
}

export default AppBarCourses
import React from 'react'
import AppBarLessonDetail from '../../Components/AppBars/AppBarCourses/AppBarLessonDetail';
import MainLayout from "../../Components/Layouts/MainLayout";

function LessonDetailPage() {
    const { AppLayout } = MainLayout();

    return <>{AppLayout(AppBarLessonDetail)}</>;
}

export default LessonDetailPage
import React from 'react'
import MainLayout from "../../Layouts/MainLayout";
import MyCourses from './MyCourses';

function AppBarMyCourses() {
    const { AppLayout } = MainLayout();

    return <>{AppLayout(MyCourses)}</>;
}

export default AppBarMyCourses


import React from 'react'
import AppBarCourses from '../Components/AppBars/AppBarCourses/AppBarCourses';
import MainLayout from "../Components/Layouts/MainLayout";

function CoursesPage() {
    const { AppLayout } = MainLayout();

    return <>{AppLayout(AppBarCourses)}</>;
}

export default CoursesPage
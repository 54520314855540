import React from 'react'
import AppBarCourseDetail from '../../Components/AppBars/AppBarCourses/AppBarCourseDetail';
import MainLayout from "../../Components/Layouts/MainLayout";

function CourseDetailPage() {
    const { AppLayout } = MainLayout();

    return <>{AppLayout(AppBarCourseDetail)}</>;
}

export default CourseDetailPage
import { useEffect, useState } from "react";
import MainRoutes from "./app/Routes/routes";

function App() {
  const [status, setStatus] = useState("");
  const [auth, setAuth] = useState("");

  useEffect(() => {
    setAuth(sessionStorage.getItem("Auth Token"));
    console.log("session=", sessionStorage.getItem("Auth Token"));
  }, [sessionStorage.getItem("Auth Token")]);
  console.log(auth);
  return <MainRoutes />;
}

export default App;

import {
    Box, Button, Flex, HStack, Text, Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    useBreakpointValue,
    ButtonGroup,
    Badge,
    useToast,
    IconButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Stack,
    Skeleton,
    Input,
    InputGroup,
    InputLeftElement,
} from '@chakra-ui/react'
import { doc, deleteDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDocInCollection } from '../../../../Api/firebase/getDoc';
import { db } from '../../../../Configs/firebase';
import { useAuthState } from '../../../../Contexts';
import ReactPaginate from 'react-paginate';
import "../../../../styles/bootstrap-513/bootstrap.min.css";
import { FiEdit, FiEye, FiTrash } from 'react-icons/fi';
import { SearchIcon } from '@chakra-ui/icons';

function AppBarAdminUsers() {
    const { user, role } = useAuthState();
    const isDesktop = useBreakpointValue({
        lg: true,
        md: false,
    });
    let navigate = useNavigate();
    const toast = useToast();

    const [users, setUsers] = useState([]);
    const [originalUsers, setOriginalUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const getUSers = async () => {
        setLoading(true);
        const x = await getDocInCollection("/user");
        console.log(x);
        setUsers(x);
        setOriginalUsers(x);

        setLoading(false);
    }

    const getUsersNonSkeleton = async () => {
        const x = await getDocInCollection("/user");
        setUsers(x);
    }

    // const handleAddCourse = async () => {
    //     try {
    //         for (let index = 0; index < users.length; index++) {
    //             const userId = users[index].userId;
    //             if (users[index].enrolledCourses === undefined) {
    //                 console.log(userId, "userId");
    //                 const docRef = await setDoc(doc(db, "user", userId), {
    //                     enrolledCourses: ["3EtqxoWgY6FxVpDgaP6u"],
    //                 }, { merge: true });
    //             }
    //             console.log("Document written with ID: ", userId);
    //         }

    //         toast({
    //             title: "Course added to all users",
    //             status: "success",
    //             duration: 9000,
    //             isClosable: true,
    //             position: "top",
    //         });
    //     } catch (error) {
    //         console.log(error.message, "error in handleAddCourse");
    //         toast({
    //             title: "Error adding course to all users",
    //             status: "error",
    //             duration: 9000,
    //             isClosable: true,
    //             position: "top",
    //         });
    //     };
    // }

    // Pagination
    function Items({ currentItems }) {
        return (
          <>
            <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th textAlign={'center'}>No</Th>
                                <Th textAlign={'center'}>Name</Th>
                                <Th textAlign={'center'}>Email</Th>
                                <Th textAlign={'center'}>Courses Enrolled</Th>
                                <Th textAlign={'center'}>Role</Th>
                                <Th textAlign={'center'}>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                        {loading ? 
                            <Tr>
                                <Td>
                                    <Stack>
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                    </Stack>
                                </Td>
                                <Td>
                                    <Stack>
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                    </Stack>
                                </Td>
                                <Td>
                                    <Stack>
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                    </Stack>
                                </Td>
                                <Td>
                                    <Stack>
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                    </Stack>
                                </Td>
                                <Td>
                                    <Stack>
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                    </Stack>
                                </Td>
                                <Td>
                                    <Stack>
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                        <Skeleton height="30px" />
                                    </Stack>
                                </Td>
                            </Tr>
                        : ''}
                        {
                            currentItems && currentItems.map((user) => (
                                <Tr key={user.no}>
                                    <Td textAlign={'center'}>{user.no}</Td>
                                    <Td textAlign={'center'}>{user.userName}</Td>
                                    <Td textAlign={'center'}>{user.userEmail}</Td>
                                    <Td textAlign={'center'}>{user.enrolledCourses !== undefined ? user.enrolledCourses.length : 0}</Td>
                                    <Td textAlign={'center'}>
                                        <Badge colorScheme={user.role === "super-admin" ? "blue" : "green"}>
                                            {user.role === "super-admin" ? "Super Admin" : user.role}
                                        </Badge>
                                    </Td>
                                    <Td textAlign={'center'}>
                                        <ButtonGroup size="sm" isAttached variant="outline">
                                            <IconButton aria-label="Show" icon={<FiEye />} onClick={() => navigate(`/my-courses/${user.userId}`)} />
                                            <IconButton aria-label="Edit" icon={<FiEdit />} onClick={() => navigate("/admin/users/edit/" + user.userId)} />
                                            <IconButton aria-label="Delete" icon={<FiTrash />} onClick={() => handleOpenDeleteModal(user.userId)} />
                                        </ButtonGroup>
                                    </Td>
                                </Tr>
                            ))
                        }
                        
                        </Tbody>
                    </Table>
            </TableContainer>

            <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Delete User</ModalHeader>
                <ModalCloseButton />
                <ModalBody className='mb-3'>
                    <p className='fw-bold'>Are you sure you want to delete this user?</p>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='gray' mr={3} onClick={() => setIsDeleteModalOpen(false)}>
                        NO
                    </Button>
                    <Button colorScheme='red' onClick={handleDelete}>
                        YES
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>

          </>
        );
    }

    function PaginatedItems({ itemsPerPage }) {
        // Here we use item offsets; we could also use page offsets
        // following the API or data you're working with.
        const [itemOffset, setItemOffset] = useState(0);
      
        // Simulate fetching items from another resources.
        // (This could be items from props; or items loaded in a local state
        // from an API endpoint with useEffect and useState)
        const endOffset = itemOffset + itemsPerPage;
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        const currentItems = users.slice(itemOffset, endOffset).map((item, index) => ({
            ...item,
            no: itemOffset + index + 1
        }));

        const pageCount = Math.ceil(users.length / itemsPerPage);
      
        // Invoke when user click to request another page.
        const handlePageClick = (event) => {

        const newOffset = (event.selected * itemsPerPage) % users.length;
          setItemOffset(newOffset);
        };
      
        return (
            <>
                <Items currentItems={currentItems} />
                <div className='d-flex justify-content-center my-3'>
                    <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    />
                </div>
          </>
        );
    }

    // delete user
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const handleDelete = async () => {
        const user = doc(db, "user", selectedUserId);
        try {
            await deleteDoc(user);
            setIsDeleteModalOpen(false);
            getUsersNonSkeleton();

            toast({
                title: "Success",
                description: "Delete user",
                status: "success",
                duration: 9000,
                position: "top",
                isClosable: true,
            });
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            console.error("Error removing user: ", error);
            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 9000,
                position: "top",
                isClosable: true,
            });
        }
    };

    const handleOpenDeleteModal = (id) => {
        setSelectedUserId(id);
        setIsDeleteModalOpen(true);
    };

    // search data
    const handleSearch = (e) => {
        const searchValue = e.target.value.toLowerCase();

        if(searchValue === ""){
            setUsers(originalUsers);
        } else {
            const newData = users.filter(row => {
                // Pastikan row.userName ada sebelum memanggil toLowerCase()
                return (row.userName && row.userName.toLowerCase().includes(searchValue)) || (row.userEmail && row.userEmail.toLowerCase().includes(searchValue));
            })
            setUsers(newData);
        }
        
    }
    
    useEffect(() => {
        getUSers();
    }, []);

    return (
        <>
        <Text fontSize="2xl" fontWeight="bold" color="on-primary" marginX={3}>Users</Text>
        <Flex bg="primary" flexDir={"column"} color="on-primary" w="100%" alignItems="center" justifyContent="space-between" px="4">
            <HStack w="100%" h="10%" color="on-secondary" borderRadius="md" m="2" justifyContent="space-between">
                <ButtonGroup spacing={3}>

                    <InputGroup>
                        <InputLeftElement pointerEvents='none'>
                            <SearchIcon color='gray.500' />
                        </InputLeftElement>
                        <Input placeholder='Search user' onChange={handleSearch} />
                    </InputGroup>

                </ButtonGroup>

                <ButtonGroup spacing="2">
                    <Button colorScheme="blue" variant="solid" size="sm" m="2" onClick={() => navigate("/admin/users/add")} >
                        Add User
                    </Button>

                    {/* <Button colorScheme="blue" variant="solid" size="sm" m="2" onClick={() => handleAddCourse()} >
                        Add Course
                    </Button> */}

                    {role === "super-admin" ? (
                        <Button colorScheme="red" variant="solid" size="sm" m="2" onClick={() => navigate("/admin/users/add-admin")} >
                            Add Admin
                        </Button>
                    ) : null}
                </ButtonGroup>
            </HStack>

            <Box w="100%" color="on-secondary" borderRadius="md" m="2" shadow={"md"} overflowY={"scroll"}>
                <PaginatedItems itemsPerPage={10} />
            </Box>
        </Flex>
        </>
    )
}


export default AppBarAdminUsers
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { db } from "../../Configs/firebase";

export const getDocInCollection = async (collRef) => {
  try {
    const q = query(collection(db, collRef));
    const querySnapshot = await getDocs(q);
    const docs = [];

    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    // console.log(docs, "this is docs");
    return docs;
  } catch (error) {
    console.log(error);
  }
};

export const  getSpecifiedDoc = async (collRef, docRef) => {
  try {
    const docSnap = doc(db, collRef, docRef);
    const docData = await getDoc(docSnap);
    if (docData.exists()) {
      // console.log({ ...docData.data(), id: docData.id });
      return { ...docData.data(), id: docData.id };
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCollectionCourseForMultipleSelect = async (collRef) => {
  try {
    const q = query(collection(db, collRef));
    const querySnapshot = await getDocs(q);
    const docs = [];

    querySnapshot.forEach((doc) => {
      docs.push({ value: doc.id, label: doc.data().title });
    });
    return docs;
  } catch (error) {
    console.log(error);
  }
};

export async function getDocumentInSubCollectionUser(userId, collectionPath, documentId) {

  try {
    const userRef = doc(db, 'user', userId);
    const subCollectionRef = collection(userRef, collectionPath);
    const documentSnapshot = await getDoc(doc(subCollectionRef, documentId));

    if (documentSnapshot.exists()) {
      return documentSnapshot.data();
    } else {
      throw new Error('Dokumen tidak ditemukan');
    }
  } catch (error) {
    console.error('Error getting document:', error);
    throw error;
  }
}

import {
    Box, Button, Container, Divider, Flex, Heading, HStack, IconButton, Stack, useColorModeValue, Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Text,
    Checkbox,
    Skeleton,
    useBreakpointValue,
    Center,
    Icon,
    Progress,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { Stream } from "@cloudflare/stream-react";
import { FiDownloadCloud } from 'react-icons/fi';
import { TbPlayerTrackPrev, TbPlayerTrackNext } from "react-icons/tb";
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { getDocumentInSubCollectionUser, getSpecifiedDoc } from '../../../Api/firebase/getDoc'; 
import { doc, setDoc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../../Configs/firebase';
import { useAuthState } from '../../../Contexts';
import { BsPlayCircleFill } from 'react-icons/bs'
import { FaCheckCircle } from 'react-icons/fa'

function AppBarLessonDetail() {
    const { user, role, enrolledCourses } = useAuthState();
    const [courseStatus, setCourseStatus] = useState("");

    const isDesktop = useBreakpointValue({
        lg: true,
        md: true,
        sm: false,
    });

    const navigate = useNavigate();
    const Card = (props) => (
        <Box
            minH="3xs"
            bg="#ffd600"
            boxShadow={useColorModeValue("sm", "sm-dark")}
            {...props}
        />
    );

    const [courses, setCourses] = useState("");
    const [currentLesson, setCurrentLesson] = useState("");
    const [loading, setLoading] = useState(false);
    const [videoSource, setVideoSource] = useState("");
    const [isYoutube, setIsYoutube] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [progressCourse, setProgressCourse] = useState({});
    const [sectionIndex, setSectionIndex] = useState([]);
    const [accordionIndex, setAccordionIndex] = useState([0]);
    const param = useParams();

    console.log(loading, "video Loaded")


    const getCourseDetail = async () => {
        setLoading(true);
        const x = await getSpecifiedDoc("/courses", param.courseId);
        // const x = await getDocumentInSubCollectionUser(user.uid, "myCourse", param.courseId);
        console.log(x);
        setCourses(x);

        // set progress course
        const progress = await calculateTotalCoursesAndCompletedCourses(x.sections);
        setProgressCourse(progress);

        const y = x.sections.filter((item) => item.sectionId === param.sectionId);
        console.log(y, "y");
        console.log(y.lessons, "y.lessons");

        const z = y[0].lessons.filter((zz) => zz.lessonId === param.lessonId);
        console.log(z, "current lesson");
        setCurrentLesson(z[0]);

        // set isCompleted
        setIsCompleted(z[0].isCompleted.includes(user.uid));

        // set accordion index
        const AccordionIndex = x.sections.findIndex((item) => item.sectionId === param.sectionId);
        setAccordionIndex(3);
        console.log(typeof AccordionIndex, "Accordion index");

        // check if video is youtube
        const youtubeRegex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+$/;
        const youtubeVideo = youtubeRegex.test(z[0].lessonVideo);
        setIsYoutube(youtubeVideo);
        if (isYoutube) {
            const videoId = z[0].lessonVideo.split('=')[1];
            setVideoSource(videoId);
        } else {
            setVideoSource(z[0].lessonVideo);
        }
        
        setLoading(false);
    }

    const calculateTotalCoursesAndCompletedCourses = async (sections) => {
        let total_courses = 0;
        let total_completed = 0;
        sections.map((section) => {
            total_courses += section.lessons.length;
            section.lessons.map((lesson) => {
                if (lesson.isCompleted.includes(user.uid)) {
                    total_completed += 1;
                }
            })
        });

        // calculate persentase
        let percentage = (total_completed / total_courses) * 100;

        return {"total_course":total_courses, "total_completed": total_completed, "percentage": percentage.toFixed()};
    }

    const handleComplete = async () => {

        // const x = await setDoc(
        //     doc(db, `user/${user.uid}/lessonStatus`, courses.id), {
        //     completedLessons: arrayUnion(currentLesson.lessonId)
        // }, { merge: true });

        try {
            const sectionIndex = courses.sections.findIndex((item) => item.sectionId === param.sectionId);
            const lessonIndex = courses.sections[sectionIndex].lessons.findIndex((item) => item.lessonId === param.lessonId);
            
            const courseRef = doc(db, "courses", param.courseId);
            getDoc(courseRef)
            .then((doc) => {
                if (doc.exists) {
                const sections = doc.data().sections;
                const lessons = sections[sectionIndex].lessons;

                // Menambahkan elemen baru ke array
                lessons[lessonIndex].isCompleted = [...lessons[lessonIndex].isCompleted, user.uid];

                // Mengupdate array
                return updateDoc(courseRef, { sections }, {merge: true});
                } else {
                    console.log('Dokumen tidak ditemukan');
                }
            })
            .then(() => {
                console.log('Data berhasil diperbarui');
            })
            .catch((error) => {
                console.error('Terjadi kesalahan saat memperbarui data:', error);
            });

        } catch (error) {
            console.error('Error updating document:', error);
            throw error;
        }

        handleNext();
    };

    const handlePrev = () => {
        // console.log("prev");
        //get section index
        const sectionIndex = courses.sections.findIndex((item) => item.sectionId === param.sectionId);
        // console.log(sectionIndex, "sectionIndex");
        //get lesson index
        const lessonIndex = courses.sections[sectionIndex].lessons.findIndex((item) => item.lessonId === param.lessonId);
        // console.log(lessonIndex, "lessonIndex");

        if (lessonIndex === 0) {
            //if lesson index is 0, go to previous section
            const prevSection = courses.sections[sectionIndex - 1];
            // console.log(prevSection, "prevSection");
            const prevLesson = prevSection.lessons[prevSection.lessons.length - 1];
            // console.log(prevLesson, "prevLesson");
            navigate(`/courses/${param.courseId}/${prevSection.sectionId}/${prevLesson.lessonId}`);
        } else {
            //if lesson index is not 0, go to previous lesson
            const prevLesson = courses.sections[sectionIndex].lessons[lessonIndex - 1];
            // console.log(prevLesson, "prevLesson");
            navigate(`/courses/${param.courseId}/${param.sectionId}/${prevLesson.lessonId}`);
        }
    };

    const handleNext = () => {
        console.log("next");
        //get section index
        const sectionIndex = courses.sections.findIndex((item) => item.sectionId === param.sectionId);
        // console.log(sectionIndex, "sectionIndex");
        //get lesson index
        const lessonIndex = courses.sections[sectionIndex].lessons.findIndex((item) => item.lessonId === param.lessonId);
        // console.log(lessonIndex, "lessonIndex");

        //check if lesson is last lesson
        if (lessonIndex === courses.sections[sectionIndex].lessons.length - 1) {
            //check if section is last section
            if (sectionIndex === courses.sections.length - 1) {
                // console.log("last lesson");
            } else {
                // console.log("next section");
                navigate(`/courses/${param.courseId}/${courses.sections[sectionIndex + 1].sectionId}/${courses.sections[sectionIndex + 1].lessons[0].lessonId}`)
            }
        } else {
            // console.log("next lesson");
            navigate(`/courses/${param.courseId}/${param.sectionId}/${courses.sections[sectionIndex].lessons[lessonIndex + 1].lessonId}`)
        }
    };

    useEffect(() => {
        getCourseDetail();
    }, [param.sectionId, param.lessonId, videoSource]);

    // new validasi after menggunakan multiple select
    const checkCourses = enrolledCourses.some(function(item) {
        return item.value === param.courseId
    })

    if (!checkCourses) {
        navigate('/courses/' + param.courseId); //redirect to course detail page'
    }

    // validasi sebelum menggunakan multiple select
    // if (!enrolledCourses.includes(param.courseId)) {
    //     navigate('/courses/' + param.courseId); //redirect to course detail page'
    // }

    return (
        <Flex w="full" h={isDesktop ? 'full' : null} align="center" justify="center" direction={isDesktop ? 'row' : "column"}>
            <Container maxW={isDesktop ? "100%" : "100%"} height={isDesktop ? 'full' : null} bgColor={"gray.100"} p={0}
                m={0} mb={isDesktop ? 0 : 10}>
                <Stack
                    // spacing={{
                    //     base: "8",
                    //     lg: "6",
                    // }}
                >
                    <Center maxW={"100%"} h={"100%"} mt={isDesktop ? 0 : 2}>
                        <iframe
                            src={isYoutube ? `https://www.youtube.com/embed/${videoSource}` : `https://customer-m4s6qyxbn871sysu.cloudflarestream.com/${videoSource}/iframe`}
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                            width={isDesktop ? "100%" : 360}
                            height={isDesktop ? 540 : 200}
                            allowFullScreen={true}
                        ></iframe>
                        {/* <Stream controls src={videoSource} /> */}
                    </Center>
                    <Box>
                        <Box borderRadius={'2xl'} mx={3} p={3} boxShadow='sm' bg={'white'} >
                            <HStack justifyContent={"space-between"}>
                                <IconButton colorScheme={'blue'} icon={<TbPlayerTrackPrev />} onClick={() => handlePrev()} borderRadius={'full'} />
                                {!isCompleted ? (
                                    <Button colorScheme="blue" variant="solid" onClick={() => handleComplete()} borderRadius={'full'}>
                                    Mark as Completed
                                    </Button>
                                ) : ""}
                                <IconButton colorScheme={'blue'} icon={<TbPlayerTrackNext />} onClick={() => handleNext()} borderRadius={'full'} />
                            </HStack>
                        </Box>
                    </Box>
                    <Box>
                        <Heading size="lg" fontWeight="bold" color={'blue.800'} ml={10} mt={3}>
                            {currentLesson.lessonTitle}
                        </Heading>

                        <Heading ml={10} mt={8} size="xs" fontWeight="medium" color={'gray.400'}>
                            Description
                        </Heading>

                        <Text mx={10} mt={2} size={"lg"} color="blackAlpha.700" fontWeight={'semibold'}>{currentLesson.lessonDescription}</Text>
                    </Box>
                </Stack>
            </Container>

            <Container maxW={isDesktop ? "25%" : "90%"} height={isDesktop ? 'full' : null} bgColor={"gray.100"} p={3} m={0}>
                {/* <Stack
                    spacing={{
                        base: "8",
                        lg: "6",
                    }}
                    p={3}
                    m={0}
                > */}
                    
                    <Box bg={'white'} py={3} px={5} borderRadius={5} height={isDesktop ? 'full' : null} maxHeight={'5xl'} overflowY={'scroll'}>
                        
                        <Text fontWeight={'bold'} mb={3}>{progressCourse.total_completed} of {progressCourse.total_course} Completed</Text>
                        <Progress hasStripe value={progressCourse.percentage} size={'sm'} colorScheme='blue' m={"-1"} />
                        <Text textAlign={'right'} mt={1}>{progressCourse.percentage}%</Text>

                        <Accordion defaultIndex={accordionIndex} allowMultiple mt={7}>
                            {courses.sections && courses.sections.map((item, index) => (
                                <>
                                    <AccordionItem key={index} >
                                        <h2>
                                            <AccordionButton bg={'gray.100'} borderRadius={'md'} my={2} py={4} onClick={() => setAccordionIndex(index)}>
                                                <Box flex='1' textAlign='left' alignItems={"center"} fontWeight={"bold"}>
                                                    {item.sectionTitle}
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <Accordion allowMultiple>
                                                {item.lessons && item.lessons.map((lesson, i) => (
                                                    <AccordionItem key={i}>
                                                        <Box flex='1' textAlign='left' alignItems={"center"} p={3} borderRadius={'xl'} my={'1'}
                                                            // conditional active class
                                                            bg={item.sectionId === param.sectionId && lesson.lessonId === param.lessonId ? "gray.700" : "white"}
                                                        >
                                                            <Link to={`/courses/${param.courseId}/${item.sectionId}/${lesson.lessonId}`} color={"black"}>
                                                                <Text fontSize="md" fontWeight="normal" _hover={{
                                                                    textDecoration: "none",
                                                                    fontWeight: "semibold"
                                                                }}
                                                                color={item.sectionId === param.sectionId && lesson.lessonId === param.lessonId ? "white" : "black"}
                                                                >
                                                                    <Icon as={BsPlayCircleFill} color={item.sectionId === param.sectionId && lesson.lessonId === param.lessonId ? "white" : "black"} mb={1} mr={2}></Icon>
                                                                    {lesson.lessonTitle}

                                                                    {Array.isArray(lesson.isCompleted) && lesson.isCompleted.includes(user.uid) ? <Icon as={FaCheckCircle} color={'green'} ml={3} mb={1}></Icon> : null}
                                                                    
                                                                </Text>
                                                            </Link>
                                                        </Box>
                                                        <AccordionPanel pb={4}>
                                                            {lesson.lessonDescription}
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                ))}
                                            </Accordion>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </>

                            ))}
                        </Accordion>
                    </Box>

                {/* </Stack> */}
            </Container>
        </Flex>
    )
}

export default AppBarLessonDetail
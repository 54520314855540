import React from 'react'
import { Route, Routes } from 'react-router-dom';
import { useAuthDispatch, useAuthState } from "../Contexts";

import HomePage from "../Pages/HomePage";
import LoginPage from "../Pages/LoginPage";
import SignUpPage from "../Pages/SignUpPage";
import DashboardPage from "../Pages/DashboardPage";
import ProtectedRoutes from './ProtectedRoutes';
import CoursesPage from '../Pages/CoursesPage';
import CourseDetailPage from '../Pages/Courses/CourseDetailPage';
import LessonDetailPage from '../Pages/Courses/LessonDetailPage';
import useAuthentication from '../Utils/useAuthentication';
import AdminPage from '../Pages/Admin/AdminPage';
import AppBarAdminCourses from '../Components/AppBars/AppBarAdmin/Courses/AppBarAdminCourses';
import AppBarAdminUsers from '../Components/AppBars/AppBarAdmin/Users/AppBarAdminUsers';
import AppBarAdminAddUsers from '../Components/AppBars/AppBarAdmin/Users/AppBarAdminAddUsers';
import AppBarAdminAddAdmin from '../Components/AppBars/AppBarAdmin/Users/AppBarAdminAddAdmin';
import AppBarAdminCourseDetail from '../Components/AppBars/AppBarAdmin/Courses/AppBarAdminCourseDetail';
import Subscriptions from '../Components/AppBars/AppBarAdmin/AppBarSubscriptions/Subscriptions';
import SubscriptionDetail from '../Components/AppBars/AppBarAdmin/AppBarSubscriptions/SubscriptionDetail';
import AppBarAdminEditUsers from '../Components/AppBars/AppBarAdmin/Users/AppBarAdminEditUsers';
import TestFunction from '../Components/TestUpdateEnrolledCoursesUser';
import AppBarMyCourses from '../Components/AppBars/AppBarCourses/AppBarMyCourses';

function MainRoutes() {
    const { user, role } = useAuthState();

    const isLoggedIn = useAuthentication();
    return (
        <Routes>
            {/* <Route path="/" element={<HomePage />} /> */}
            <Route path="/my-courses/:userId" element={<AppBarMyCourses />} />
            <Route path="/" element={<LoginPage />} />
            <Route path="signup" element={<SignUpPage />} />
            <Route path="courses" element={
                <ProtectedRoutes user={user} redirectPath={"/"}>
                    <CoursesPage />
                </ProtectedRoutes>
            } />
            <Route path='courses/:courseId' element={
                <ProtectedRoutes user={user} redirectPath={"/"}>
                    <CourseDetailPage />
                </ProtectedRoutes>
            } />
            <Route path='courses/:courseId/:sectionId/:lessonId' element={
                <ProtectedRoutes user={user} redirectPath={"/"}>
                    <LessonDetailPage />
                </ProtectedRoutes>
            } />

            {role !== "user" && (
                <Route
                    path="admin"
                    element={
                        <ProtectedRoutes user={user} redirectPath={"/"}>
                            <AdminPage />
                        </ProtectedRoutes>
                    }>
                    <Route path="users" element={<AppBarAdminUsers />} />
                    <Route path="users/add" element={<AppBarAdminAddUsers />} />
                    <Route path="users/add-admin" element={<AppBarAdminAddAdmin />} />
                    <Route path="users/edit/:userId" element={<AppBarAdminEditUsers />} />

                    <Route path="courses" element={<AppBarAdminCourses />} />
                    <Route path="courses/:id" element={<AppBarAdminCourseDetail />} />
                    <Route path="subscriptions" element={<Subscriptions />} />
                    <Route path="subscriptions/:id" element={<SubscriptionDetail />} />
                </Route>
            )}
        </Routes>

    )
}

export default MainRoutes;
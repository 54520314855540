import { wait } from "@testing-library/user-event/dist/utils";
import {
  signInWithEmailAndPassword,
  updatePhoneNumber,
  updateProfile,
  signOut,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { auth, db } from "../Configs/firebase";

export async function SignupUser(dispatch, signupPayload) {
  const userData = {
    email: signupPayload.email,
    password: signupPayload.password,
    name: signupPayload.name,
    role: "user",
  };

  try {
    const data = await createUserWithEmailAndPassword(
      auth,
      userData.email,
      userData.password
    );

    await updateProfile(auth.currentUser, {
      displayName: userData.name,
    });

    console.log(data, "data");

    await setDoc(doc(db, "user", data.user.uid), {
      userId: data.user.uid,
      userName: userData.name,
      userEmail: userData.email,
      role: userData.role,
    });

    if (data.user) {
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: {
          user: data.user,
          role: userData.role,
        },
      });

      console.log(data.user.uid, "setDoc Success");

      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem("role", JSON.stringify(userData.role));

      console.log("localStorage set done");
      return data.user;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function LoginUser(dispatch, loginPayload) {
  var store = require("store");
  const userData = {
    email: loginPayload.email,
    password: loginPayload.password,
  };
  try {
    dispatch({ type: "REQUEST_LOGIN" });
    store.clearAll();

    let response = await signInWithEmailAndPassword(
      auth,
      userData.email,
      userData.password
    );

    let data = response;

    const ref = doc(db, "user", data.user.uid);
    const docSnap = await getDoc(ref);

    if (data.user.displayName === null || undefined || "") {
      const user = docSnap.data();
      updateProfile(auth.currentUser, {
        displayName: user.userName,
      });
      console.log("update profile done");
    }

    console.log("process completed, continue to set localStorage");
    if (data.user) {
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: {
          user: data.user,
          role: docSnap.data().role,
          enrolledCourses: docSnap.data().enrolledCourses,
        },
      });

      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem("role", JSON.stringify(docSnap.data().role));
      localStorage.setItem(
        "enrolledCourses",
        JSON.stringify(docSnap.data().enrolledCourses)
      );

      console.log("localStorage set done");
      return data;
    } else {
      return false;
    }
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", errorMessage: { error: error } });
  }
}

export async function logout(dispatch) {
  dispatch({ type: "LOGOUT" });
  signOut(auth);
  sessionStorage.removeItem("Auth Token");
  localStorage.removeItem("user");
  localStorage.removeItem("role");
  localStorage.removeItem("enrolledCourses");
}

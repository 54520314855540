import { Box, Button, Divider, Flex, FormControl, FormLabel, Heading, HStack, Input, SimpleGrid, useToast } from '@chakra-ui/react';
import Select from 'react-select';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../../../Configs/firebase';
import { useAuthState } from '../../../../Contexts';
import { getCollectionCourseForMultipleSelect, getDocInCollection } from '../../../../Api/firebase/getDoc';
import { useEffect } from 'react';

function AppBarAdminAddUsers() {
    const { user, role, loading } = useAuthState();

    const [data, setData] = useState({});
    const [userPassword, setUserPassword] = useState("");
    const [confirmationPassword, setConfirmationPassword] = useState("");
    const [enrolledCourses, setEnrolledCourses] = useState([]);
    const [options, setOptions] = useState([]);

    const toast = useToast();
    const navigate = useNavigate();

    const handleData = (key, value) => {
        let newState = data;
        newState[key] = value;
        setData(newState);
    };

    const getCourses = async () => {
        const x = await getCollectionCourseForMultipleSelect("/courses");
        setOptions(x)
    }

    const handleEnrolledCourses = (event) => {
        setEnrolledCourses(event);
    }

    const onHandleRegister = async () => {
        createUserWithEmailAndPassword(auth, data.userEmail, userPassword)
            .then(async (res) => {
                console.log(res.user.uid, "newUser");
                if (res) {
                    data.userId = res.user.uid;
                    data.role = "user";
                    data.timestamp = serverTimestamp();
                    data.createdBy = user.uid;
                    data.enrolledCourses = enrolledCourses;

                    const updateDB = await setDoc(
                        doc(db, "user", res.user.uid),
                        data
                    );

                    toast({
                        title: "Success",
                        description: "New user added",
                        status: "success",
                        duration: 9000,
                        position: "top",
                        isClosable: true,
                    });
                }
            }).finally(() => {
                navigate("/admin/users");
            })
            .catch((error) => {
                console.log(error, "error");
                toast({
                    title: "Error",
                    description: error.message,
                    status: "error",
                    duration: 9000,
                    position: "top",
                    isClosable: true,
                });
            })
    };

    useEffect(() => {
        getCourses();
    }, []);

    return (
        <Box as="nav" h={"80px"} pt={1}>
            <Box maxW="1440" p={"5"}>
                <Heading as="h3" size="md" color="on-accent">
                    Add Users
                </Heading>
            </Box>

            <Divider />

            <SimpleGrid columns={2} spacing={3} p={5}>
                <Box>
                    <FormControl isRequired>
                        <FormLabel htmlFor="karyawan">Full Name</FormLabel>
                        <Input
                            id="userName"
                            type="text"
                            onChange={(e) => handleData(e.target.id, e.target.value)}
                        />
                    </FormControl>
                </Box>

                <Box>
                    <FormControl isRequired>
                        <FormLabel htmlFor="karyawan">Email</FormLabel>
                        <Input
                            id="userEmail"
                            type="email"
                            onChange={(e) => handleData(e.target.id, e.target.value)}
                        />
                    </FormControl>
                </Box>

                <Box>
                    <FormControl isRequired>
                        <FormLabel htmlFor="karyawan">Password</FormLabel>
                        <Input
                            id="password"
                            type="text"
                            onChange={(e) => setUserPassword(e.target.value)}
                        />
                    </FormControl>
                </Box>

                <Box>
                    <FormControl isRequired>
                        <FormLabel htmlFor="karyawan">Confirm Password</FormLabel>
                        <Input
                            id="password"
                            type="text"
                            onChange={(e) => setConfirmationPassword(e.target.value)}
                        />
                    </FormControl>
                </Box>
                <Box>
                    <FormControl isRequired>
                        <FormLabel htmlFor="karyawan">Courses</FormLabel>
                        {/* <Select
                            id="enrolledCourses"
                            type="text"
                            onChange={(e) => handleData(e.target.id, e.target.value)}
                            placeholder="Select Courses"
                        >
                            {courses.map((course, i) => (
                                    <option value={course.id} key={i}>{course.title}</option>
                                ))
                            }
                        </Select> */}

                        <Select 
                            id="enrolledCourses"
                            isMulti 
                            options={options}
                            onChange={handleEnrolledCourses}
                        />
                    </FormControl>
                </Box>
            </SimpleGrid>

            <HStack spacing={4} p={5} justifyContent={"flex-end"}>
                <Button colorScheme={"blue"} onClick={() => onHandleRegister()}>
                    Create User
                </Button>
            </HStack>
        </Box >
    )
}

export default AppBarAdminAddUsers
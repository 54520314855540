import React, { useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { auth, db } from "../Configs/firebase";
import { Logo } from "../Components/AppComponents/LogoComponent";
import { OAuthButtonGroup } from "../Components/AppComponents/OAuthButtonApp";
import { LoginUser, useAuthDispatch, useAuthState } from "../Contexts";
import KtLogoComponent from "../Components/AppComponents/KtLogoComponent";
import { waitFor } from "@testing-library/react";

function LoginPage() {
    var store = require("store");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    let navigate = useNavigate();
    const toast = useToast();
    const dispatch = useAuthDispatch()
    const { role, loading, errorMessage } = useAuthState(); //read the values of loading and errorMessage from context

    const onHandleLogin = async (e) => {
        let payload = {
            email,
            password
        }
        // email
        try {
            let response = await LoginUser(dispatch, payload) //loginUser action makes the request and handles all the neccessary state changes
            console.log(response, "login response")
            if (response === undefined) {
                toast({
                    title: "Login Failed",
                    description: "check your email and password",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });

            } else if (response.user.uid !== undefined && role === "users" || "admin") {
                navigate('/courses') //navigate to feeds on success
            }
        } catch (error) {
            console.log(error.message)
            toast({
                title: error,
                description: "check your email and password",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };
    return (
        <Container
            maxW="lg"
            py={{ base: "12", md: "24" }}
            px={{ base: "0", sm: "8" }}
        >
            <Stack spacing="8">
                <Stack spacing="6">
                    <Box w="400px" alignSelf="center">
                        <KtLogoComponent />
                    </Box>
                    <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
                        <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>
                            Log in to your account
                        </Heading>
                        {/* <HStack spacing="1" justify="center">
                            <Text color="muted">Don't have an account?</Text>
                            <Button variant="link" colorScheme="blue">
                                <Link to="/signup">Sign up</Link>
                            </Button>
                        </HStack> */}
                    </Stack>
                </Stack>
                <Box
                    py={{ base: "0", sm: "8" }}
                    px={{ base: "4", sm: "10" }}
                    bg={useBreakpointValue({ base: "transparent", sm: "bg-surface" })}
                    boxShadow={{ base: "none", sm: useColorModeValue("md", "md-dark") }}
                    borderRadius={{ base: "none", sm: "xl" }}
                >
                    <Stack spacing="6">
                        <Stack spacing="5">
                            <FormControl>
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <Input
                                    id="email"
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="password">Password</FormLabel>
                                <Input
                                    id="password"
                                    type="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </FormControl>
                            {/* <PasswordField /> */}
                        </Stack>

                        <HStack justify="space-between">
                            <Checkbox defaultIsChecked>Remember me</Checkbox>
                            <Button variant="link" colorScheme="blue" size="sm">
                                Forgot password?
                            </Button>
                        </HStack>

                        <Button colorScheme="blue" onClick={() => onHandleLogin()}>
                            Login
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        </Container>
    );
}

export default LoginPage;

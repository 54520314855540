import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Container,
    Divider,
    Flex,
    Heading,
    HStack,
    SimpleGrid,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Checkbox,
    SkeletonText,
    Skeleton,
    Avatar,
    Icon,
} from "@chakra-ui/react";
import { MdOndemandVideo } from 'react-icons/md'
import { Link, useNavigate, useParams } from "react-router-dom";
import { getSpecifiedDoc } from "../../../Api/firebase/getDoc";
import { IoMdLock } from "react-icons/io";
import { useAuthState } from "../../../Contexts";

function AppBarCourseDetail() {
    const { user, role, enrolledCourses } = useAuthState();
    const [courses, setCourses] = useState({});
    const [loading, setLoading] = useState(false);
    const [own, setOwn] = useState(false);
    const param = useParams();
    const navigate = useNavigate();

    const isDesktop = useBreakpointValue({
        lg: true,
        md: false,
    });



    const getCourseDetail = async () => {
        setLoading(true);
        const x = await getSpecifiedDoc("/courses", param.courseId);

        setCourses(x);
        setLoading(false);

        // validasi sebelum menggunakan multiple select
        // if (enrolledCourses.includes(param.courseId)) {
        //     setOwn(true);
        // }

        // new validasi after menggunakan multiple select
        const findUser = await getSpecifiedDoc("user", user.uid);
        const checkCourses = findUser.enrolledCourses.some(function(item) {
            return item.value === param.courseId
        })

        if (checkCourses) {
            setOwn(true);
        }
    }

    const Card = (props) => (
        <Box
            minH="3xs"
            bg="#024BAC"
            boxShadow={useColorModeValue("sm", "sm-dark")}
            borderRadius="lg"
            {...props}
            bgImage={props.bgImage}
            bgSize="cover"
            bgPosition="center"
        />
    );

    useEffect(() => {
        getCourseDetail();
    }, []);
    return (
        <Container maxW="container.xl" py="8" flex="1" mb={10}>
            <Box
                spacing={{
                    base: "8",
                    lg: "6",
                }}
            >
                {!loading ? (
                    <Card minH={isDesktop ? "sm" : "210px"} bgImage={courses.imageUrl} />
                ) : (<Skeleton minH={"sm"} rounded={"lg"} />)}

                <Box display={"flex"} flex={1} justify="space-between" mt={5} flexDirection={isDesktop ? "row" : "column-reverse"}>
                    <Stack spacing={5} mb={5} width={isDesktop ? "70%" : "95%"} m={2}>
                        <Box>
                            <Text fontSize="lg" fontWeight="bold" mb={2}>
                                Course Description
                            </Text>
                            <Divider borderColor={"blackAlpha.500"} />
                            {!loading ? (
                                <Text fontSize="md" fontWeight="normal" mt={2} p={3}>
                                    {courses.description}
                                </Text>) : <SkeletonText mt='4' noOfLines={4} spacing='4' />}
                        </Box>

                        <Box >
                            <Text fontSize="lg" fontWeight="bold" mb={2}>
                                Course Contents
                            </Text>

                            <Box mt={2}>
                                <Accordion defaultIndex={[0]} allowToggle>
                                    {courses.sections && courses.sections.map((item, index) => (
                                        <AccordionItem key={index}>
                                            <h2>
                                                <AccordionButton>
                                                    <Box flex='1' textAlign='left' alignItems={"center"} fontWeight={"bold"}>
                                                        {index + 1}. {item.sectionTitle}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Accordion allowToggle>
                                                    {item.lessons && item.lessons.map((lesson, i) => (
                                                        <AccordionItem key={i}>
                                                            <Box flex='1' textAlign='left' alignItems={"center"} p={3}>
                                                                <Link to={own ? `${item.sectionId}/${lesson.lessonId}` : ""} color={"black"}>
                                                                    <Text fontSize="md" fontWeight="normal" _hover={{
                                                                        textDecoration: "none",
                                                                        color: "blue.500",
                                                                        fontWeight: "semibold"
                                                                    }}>
                                                                        {own ? (
                                                                            < Icon mr={2} mb={1} color={'black'} as={MdOndemandVideo} />
                                                                        ) : (<Icon mr={4} mt={1} as={IoMdLock} color={"blue.500"} />)}
                                                                        {lesson.lessonTitle}
                                                                    </Text>
                                                                </Link>
                                                            </Box>
                                                            <AccordionPanel pb={4}>
                                                                {lesson.description}
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    ))}
                                                </Accordion>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    ))}
                                </Accordion>

                            </Box>
                        </Box>
                    </Stack>

                    <Stack spacing="4" bgColor={"blue.100"} width={isDesktop ? "30%" : "95%"} rounded={10} shadow={"md"} h={"-webkit-fit-content"} p={5} m={3}>

                        <Stack alignItems={"center"} spacing={5}>
                            <Text textAlign={"center"} fontSize="lg" fontWeight="bold">
                                Course Instructor
                            </Text>
                            <Box display={"flex"} flexDir={"column"} alignItems={"center"}>
                                <Avatar size={"xl"} src={courses.insctructorImage} />

                                <Text fontSize="lg" fontWeight="semibold" mt={2} fontFamily={"heading"}>
                                    {courses.instructor}
                                </Text>
                            </Box>
                            {own ? (
                                <Button colorScheme="blue" variant="outline" mt={5} mb={5} w={"80%"} _hover={{
                                    bg: "blue.500",
                                    color: "white",
                                    shadow: "md",
                                }}
                                    onClick={() => navigate(`/courses/${param.courseId}/${courses.sections[0].sectionId}/${courses.sections[0].lessons[0].lessonId}`)}
                                >
                                    Start Course
                                </Button>
                            ) : (
                                <Button colorScheme="green" variant="solid" mt={5} mb={5} w={"80%"} _hover={{
                                    bg: "green",
                                    color: "white",
                                    shadow: "md",
                                }}>
                                    Enroll Course
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </Container >
    )
}

export default AppBarCourseDetail
import React, { useEffect, useState } from "react"
import { Box,  Image, Flex, Text, Badge, Container, Stack, SimpleGrid, HStack, Progress, Skeleton } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { getSpecifiedDoc } from "../../../Api/firebase/getDoc";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../Configs/firebase";

function MyCourses() {
    const params = useParams();
    const [coursesData, setCoursesData] = useState([]);
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState([]);


    const findCourses = async () => {
        try {
            setLoading(true);
            const user = await getSpecifiedDoc("user", params.userId);
            setUser(user);
            const courses = user.enrolledCourses ?? [];
            const fetchedCourseList = [];

            // Menggunakan for...of untuk menunggu seluruh permintaan asinkron selesai
            for (const item of courses) {
                const docSnap = doc(db, "courses", item.value);
                const docData = await getDoc(docSnap);

                if (docData.exists()) {
                    const course = { ...docData.data(), id: docData.id };
                    const progress = await calculateTotalCoursesAndCompletedCourses(course.sections);

                    fetchedCourseList.push({ ...course, ...progress });
                } else {
                    console.log(`Dokumen dengan ID ${item.value} tidak ditemukan.`);
                }
            }
        
            setCoursesData(fetchedCourseList);
            setLoading(false);
        } catch (error) {
          console.log(error);
        }
    };

    const calculateTotalCoursesAndCompletedCourses = async (sections) => {
        let total_courses = 0;
        let total_completed = 0;
        sections.map((section) => {
            total_courses += section.lessons.length;
            section.lessons.map((lesson) => {
                if (lesson.isCompleted.includes(params.userId)) {
                    total_completed += 1;
                }
            })
        });

        // calculate persentase
        let percentage = (total_completed / total_courses) * 100;

        return {"total_course":total_courses, "total_completed": total_completed, "percentage_course": percentage.toFixed()};
    }

    const CardSkeleton = () => {
        return (
            <>
                <Skeleton height='sm' rounded={"md"} />
                <Skeleton height='sm' rounded={"md"} />
                <Skeleton height='sm' rounded={"md"} />
                <Skeleton height='sm' rounded={"md"} />
                <Skeleton height='sm' rounded={"md"} />
                <Skeleton height='sm' rounded={"md"} />
                <Skeleton height='sm' rounded={"md"} />
                <Skeleton height='sm' rounded={"md"} />

            </>
        )
    }

    const CardCourses = () => {
        return (
            <>
                {coursesData.map((value, index) => (
                    <Box key={index} maxW='sm' rounded='lg' overflow='hidden' shadow={"base"} _hover={{ boxShadow: "md", transform: "translateY(-5px)" }}  transition="all 0.2s linear" onClick={() => navigate(`/courses/${value.id}`)}>
                        <Image src={value.imageUrl}/>

                        <Flex alignItems='center' justifyContent='space-between'>
                            <Box p='4' w={'100%'}>
                                <Badge fontSize={11} borderRadius='full' px='2' colorScheme='teal' my={3}>
                                    {value.type}
                                </Badge>

                                <Text
                                    mt='1'
                                    fontWeight='semibold'
                                    as='h1'
                                    lineHeight='tight'
                                    fontSize={"sm"}
                                >
                                    {value.title}
                                </Text>

                                <Text
                                    mt='1'
                                    fontWeight='semibold'
                                    as='h1'
                                    lineHeight='tight'
                                    fontSize={"11"}
                                    color={"gray.500"}
                                >
                                    {value.instructor}
                                </Text>

                                <Box>
                                    <Text fontSize={11} fontWeight={'semibold'} mb={2} mt={3}>{value.total_completed} of {value.total_course} Completed</Text>
                                    <Progress hasStripe value={value.percentage_course} size={'sm'} m={"-1"} colorScheme='blue' />
                                    <Text textAlign={'right'} mt={1} fontSize={12} fontWeight={'semibold'}>{value.percentage_course}%</Text>
                                </Box>
                            
                            </Box>
                        </Flex>
                    </Box >
                ))}
            </>
        )
    }

    useEffect(() => {
        findCourses();
    }, []);

    return (

        <Container maxW="container.xl" py="8" flex="1" mb={10}>
            <Stack
                spacing={{
                    base: "8",
                    lg: "6",
                }}
            >

                <Box >
                    <HStack spacing="4" mt={5} mb={5}>
                        <Text fontSize="lg" fontWeight="bold">
                            Courses Progress {user.userName}
                        </Text>
                    </HStack>
                    <SimpleGrid
                        columns={{
                            base: 1,
                            md: 4,
                        }}
                        gap="6"
                    >

                        {loading ? (CardSkeleton()) : (CardCourses())}

                    </SimpleGrid>
                </Box>
            </Stack>
        </Container >
        
            
    )
}

export default MyCourses
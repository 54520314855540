import {
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Container,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    Flex,
    HStack,
    IconButton,
    useBreakpointValue,
    useDisclosure,
    Select,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Image,
    SimpleGrid,
} from "@chakra-ui/react";
import * as React from "react";
import { Link } from "react-router-dom";

import { Sidebar } from "./AppBarSide";
import { ToggleButton } from "../AppComponents/ToogleButton";
import { useNavigate } from "react-router-dom";
import { logout, useAuthDispatch, useAuthState } from "../../Contexts";
import { useEffect } from "react";

export const Navbar = () => {
    const { user, role, loading } = useAuthState();
    const isDesktop = useBreakpointValue({
        lg: true,
        md: false,
    });
    let navigate = useNavigate();
    const dispatch = useAuthDispatch();
    console.log("role", role);

    const { isOpen, onToggle, onClose } = useDisclosure();

    const handleLogOut = () => {
        logout(dispatch) //call the logout action
            .then(() => {
                navigate("/", { replace: true });
            })
            .catch((error) => {
                // An error happened.
                console.log(error.message, "error in handle log out");
            });
    };

    useEffect(() => {
    }, [role]);

    return (
        <Box as="nav" color="on-accent" shadow="base" h={"80px"} pt={1}>
            <Container maxW="1440" p="2">
                <Flex align="center" justify="space-between">
                    <HStack spacing="4" justifyContent={"space-between"}>
                        <HStack>
                            <Link to="/courses">
                                <Image maxW={"220px"} src="https://ik.imagekit.io/7j3gwo1p1/KelasTambahan_Logo_edited_3u2w6aVDq.png?ik-sdk-version=javascript-1.4.3&updatedAt=1661099961883" alt="Kelas Tambahan" />
                            </Link>
                            {isDesktop && (
                                <ButtonGroup variant="ghost-on-accent" spacing="1">
                                    <Button>
                                        <Link to="/courses">Courses</Link>
                                    </Button>
                                </ButtonGroup>
                            )}
                        </HStack>
                    </HStack>
                    {isDesktop ? user !== "" ? (
                        <HStack>
                            {role !== "user" ? (
                                <Button variant={"outline"} colorScheme={"blue"} onClick={() => navigate("/admin/users")} mr={3}>
                                    Admin
                                </Button>) : (null)}

                            <Avatar size="md" name={user.displayName} src={user.photoURL} />
                            <Menu>
                                <MenuButton as={Button} variant="ghost-on-accent" _hover={{ bg: "blue.500", color: "white" }}>
                                    {user.displayName}
                                </MenuButton>
                                <MenuList>
                                    {/* <MenuItem>
                                        Profile
                                    </MenuItem> */}
                                    <MenuItem onClick={() => navigate(`/my-courses/${user.uid}`)}>
                                        My Courses
                                    </MenuItem>
                                    <MenuItem onClick={handleLogOut}>
                                        Logout
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                    ) : (
                        <SimpleGrid columns={2} spacing={4}>
                            <Button colorScheme={"blue"}>
                                <Link to="/login">Login</Link>
                            </Button>
                            <Button colorScheme={"orange"}>
                                <Link to="/signup">Signup</Link>
                            </Button>
                        </SimpleGrid>)
                        : (
                            <>
                                <ToggleButton
                                    isOpen={isOpen}
                                    aria-label="Open Menu"
                                    onClick={onToggle}
                                />
                                <Drawer
                                    isOpen={isOpen}
                                    placement="left"
                                    onClose={onClose}
                                    isFullHeight
                                    preserveScrollBarGap // Only disabled for showcase
                                    trapFocus={false}
                                >
                                    <DrawerOverlay />
                                    <DrawerContent
                                        maxWidth={"fit-content"}
                                        roundedBottomRight={20}
                                        roundedTopRight={20}
                                    >
                                        <Sidebar />
                                    </DrawerContent>
                                </Drawer>
                            </>
                        )}
                </Flex>
            </Container>
        </Box>
    );
};

import {
    Divider,
    Flex,
    Text,
    Spacer,
    Stack,
    Button,
    Center,
    Progress,
    Image,
} from "@chakra-ui/react";
import * as React from "react";
import {
    FiHelpCircle,
    FiHome,
    FiSettings,
    FiLogOut,
    FiRefreshCcw,
    FiZap,
    FiServer,
    FiUsers,
    FiKey,
    FiDollarSign,
    FiBook,
} from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";

import { signOut } from "firebase/auth";
import { auth } from "../../Configs/firebase";
import { NavButton } from "../AppComponents/AppButtonNavBar";
import { Logo } from "../AppComponents/LogoComponent";
import { UserProfile } from "../AppComponents/UserProfile";

export const Sidebar = () => {
    let navigate = useNavigate();
    const email = "auth.currentUser.email";

    const handleLogOut = () => {
        signOut(auth)
            .then(() => {
                sessionStorage.removeItem("Auth Token");
                navigate("/", { replace: true });
            })
            .catch((error) => {
                // An error happened.
            });
    };
    return (
        <Flex
            flex="1"
            bg="#fff"
            color="on-accent"
            overflowY="auto"
            height="full"
            justifyContent="flex-end"
            maxW={{
                base: "fit-content", // 0-48em
                sm: "fit-content",
            }}
            py={{
                base: "6",
                sm: "8",
            }}
            px={{
                base: "4",
                sm: "8",
            }}
            shadow="xl"
            roundedBottomRight={20}
            roundedTopRight={20}
        >
            <Stack justify="space-between" spacing="1">
                <Stack
                    spacing={{
                        sm: "6",
                    }}
                    shouldWrapChildren
                >
                    <Link to="/">
                        <Image maxW={"220px"} src="https://ik.imagekit.io/7j3gwo1p1/KelasTambahan_Logo_edited_3u2w6aVDq.png?ik-sdk-version=javascript-1.4.3&updatedAt=1661099961883" alt="Kelas Tambahan" />
                    </Link>
                    <Stack spacing="1" mt={5}>
                        <NavButton
                            label="Courses"
                            icon={FiBook}
                            aria-current="page"
                            onClick={() => navigate("/courses")}
                        />
                    </Stack>
                </Stack>
                <Stack
                    spacing={{
                        base: "5",
                        sm: "6",
                    }}
                >
                    <Stack spacing="1">
                        <Button onClick={handleLogOut} variant={"outline"} colorScheme={"red"} leftIcon={<FiLogOut />}>
                            Logout
                        </Button>
                        {/* <UserProfile image="https://tinyurl.com/yhkm2ek8" email={email} /> */}
                        {/* <NavButton
                onClick={() => handleLogOut()}
                label="Logout"
                icon={FiLogOut}
              /> */}
                    </Stack>
                </Stack>
            </Stack>
        </Flex>
    );
};

import React, { useState } from 'react'
import {
    Box,
    Button,
    ButtonGroup,
    Container,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    Avatar,
    Badge,
    Checkbox,
    IconButton,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    FormLabel,
    SimpleGrid,
} from '@chakra-ui/react'
import { FiEdit2, FiTrash2, FiSearch, FiEye } from 'react-icons/fi'
import { IoArrowDown } from 'react-icons/io5'
import { getDocInCollection } from '../../../../Api/firebase/getDoc'
import { useEffect } from 'react'
import { currencyFormat } from '../../../../Utils/currencyFormat'
import { useNavigate } from 'react-router-dom'

function AppBarAdminCourses() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [courses, setCourses] = useState([])

    const [newCourse, setNewCourse] = useState({
        title: '',
        description: '',
        price: 0,
        imageUrl: '',
        instructor: '',
        insctructorImage: '',
        type: '',
        status: 'draft',
        imageAlt: '',
        sections: [
            {
                sectionId: 'section1',
                sectionTitle: 'section1',
                sectionDescription: '',
                lessons: [
                    {
                        lessonId: 'lesson1',
                        lessonTitle: 'lesson1',
                        lessonDescription: '',
                        lessonVideo: '',
                    }
                ]
            }
        ],
    })

    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    })

    const handleGetCourses = async () => {
        const q = await getDocInCollection("/courses");

        console.log(q);
        setCourses(q);
    };

    useEffect(() => {
        handleGetCourses();
    }, []);

    return (
        <Container
            py={{
                base: '4',
                md: '8',
            }}
            px={{
                base: '0',
                md: 8,
            }}
            maxW="container.xl"
        >
            <Box
                bg="bg-surface"
                boxShadow={{
                    base: 'none',
                    md: useColorModeValue('sm', 'sm-dark'),
                }}
                borderRadius={useBreakpointValue({
                    base: 'none',
                    md: 'lg',
                })}
                w={"100%"}
            >
                <Stack spacing="5" w={"100%"}>
                    <Box
                        px={{
                            base: '4',
                            md: '6',
                        }}
                        pt="5"
                    >
                        <Stack
                            direction={{
                                base: 'column',
                                md: 'column',
                            }}
                            justify="space-between"
                        >
                            <Text fontSize="lg" fontWeight="medium">
                                Courses
                            </Text>
                            <HStack justifyContent={"space-between"}>
                                <InputGroup maxW="xs">
                                    <InputLeftElement pointerEvents="none">
                                        <Icon as={FiSearch} color="muted" boxSize="5" />
                                    </InputLeftElement>
                                    <Input placeholder="Search" />
                                </InputGroup>

                                <Button
                                    colorScheme="blue"
                                    size="md"
                                    leftIcon={<Icon as={FiEdit2} boxSize="4" />}
                                    onClick={onOpen}
                                >
                                    Add Course
                                </Button>
                            </HStack>
                        </Stack>
                    </Box>

                    <Box overflowX="auto" w={"100%"}>
                        <MemberTable members={courses} />
                    </Box>
                    <Box
                        px={{
                            base: '4',
                            md: '6',
                        }}
                        pb="5"
                    >
                        <HStack spacing="3" justify="space-between">
                            {!isMobile && (
                                <Text color="muted" fontSize="sm">
                                    Showing 1 to 5 of 42 results
                                </Text>
                            )}
                            <ButtonGroup
                                spacing="3"
                                justifyContent="space-between"
                                width={{
                                    base: 'full',
                                    md: 'auto',
                                }}
                                variant="secondary"
                            >
                                <Button>Previous</Button>
                                <Button>Next</Button>
                            </ButtonGroup>
                        </HStack>
                    </Box>
                </Stack>
            </Box >

            <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create New Course</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                    </ModalBody>
                    <SimpleGrid columns={2} spacing={4} px={5}>
                        <FormControl id="title">
                            <FormLabel fontSize="sm">Title</FormLabel>
                            <Input type="text" placeholder="Title" value={newCourse.title} onChange={
                                (e) => {
                                    setNewCourse({
                                        ...newCourse,
                                        title: e.target.value
                                    })
                                }
                            } />
                        </FormControl>

                        <FormControl id="description">
                            <FormLabel fontSize="sm">Description</FormLabel>
                            <Input type="text" placeholder="Description" value={newCourse.description} onChange={
                                (e) => {
                                    setNewCourse({
                                        ...newCourse,
                                        description: e.target.value
                                    })
                                }
                            } />
                        </FormControl>

                        <FormControl id="price">
                            <FormLabel fontSize="sm">Price</FormLabel>
                            <Input type="number" placeholder="Price" value={newCourse.price} onChange={
                                (e) => {
                                    setNewCourse({
                                        ...newCourse,
                                        price: e.target.valueAsNumber
                                    })
                                }
                            } />
                        </FormControl>
                    </SimpleGrid>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button variant='ghost'>Submit</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Container >
    )
}

const MemberTable = (props) => {
    const members = props.members || [];
    const navigate = useNavigate();
    return (
        <Table {...props}>
            <Thead>
                <Tr>
                    <Th>
                        <HStack spacing="3">
                            <HStack spacing="1">
                                <Text>Courses</Text>
                            </HStack>
                        </HStack>
                    </Th>
                    <Th>Instructor</Th>
                    <Th></Th>
                </Tr>
            </Thead>
            <Tbody>
                {members.map((member) => (
                    <Tr key={member.id}>
                        <Td>
                            <HStack spacing="3">
                                <HStack spacing="5">
                                    <Image src={member.imageUrl} alt={member.instructor} w={"100px"} />
                                    <Box textAlign="left">
                                        <Text ml="3" fontWeight="medium">
                                            {member.title}
                                        </Text>
                                        <Text ml="3" color="muted">
                                            {currencyFormat(member.price)}
                                        </Text>
                                    </Box>
                                </HStack>
                            </HStack>
                        </Td>
                        <Td>
                            <HStack spacing="3">
                                <Avatar name={member.instructor} src={member.insctructorImage} boxSize="10" />
                                <Box>
                                    <Text fontWeight="medium">{member.instructor}</Text>
                                    <Text color="muted">{member.handle}</Text>
                                </Box>
                            </HStack>
                        </Td>
                        <Td>
                            <Badge size="sm" colorScheme={member.status === 'published' ? 'green' : 'red'}>
                                {member.status}
                            </Badge>
                        </Td>
                        <Td>
                            <HStack spacing="1">
                                <IconButton
                                    icon={<FiEye fontSize="1.25rem" />}
                                    variant="ghost"
                                    aria-label="course detail"
                                    onClick={() => navigate(`/admin/courses/${member.id}`)}
                                />
                            </HStack>
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    )
}

export default AppBarAdminCourses
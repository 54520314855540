import { Badge, Box, Button, Flex, Image, Text, } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '../../Contexts';
import { currencyFormat } from '../../Utils/currencyFormat';
import { getSpecifiedDoc } from '../../Api/firebase/getDoc';

function AppCoursesCard(props) {
    const property = props.property;
    const { user, role, enrolledCourses } = useAuthState();
    let navigate = useNavigate();
    const [imgLoaded, setImgLoaded] = useState(false);
    const [checkCourse, setCheckCourse] = useState([]);


    const checkAccessCourse = async () => {
        const findUser = await getSpecifiedDoc("user", user.uid);
        const item = findUser.enrolledCourses.find((item) => item.value === property.id);
        setCheckCourse(item ? true : false);
    }
    
    useEffect(() => {
        checkAccessCourse();
    }, []);

    return (
        <Box maxW='sm' rounded='lg' overflow='hidden' shadow={"base"} _hover={{ boxShadow: "dark-lg", transform: "translateY(-5px)" }} onClick={() => navigate(`/courses/${property.id}`)} transition="all 0.2s linear" >
            <Image onLoad={() => setImgLoaded(true)} src={imgLoaded ? property.imageUrl : "https://ik.imagekit.io/7j3gwo1p1/Untitled_design_wbzgds2v_.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662207943274"} alt={property.imageAlt} />

            <Flex alignItems='center' justifyContent='space-between' pb={5}>
                <Box p='4'>
                    <Text
                        mt='1'
                        fontWeight='semibold'
                        as='h1'
                        lineHeight='tight'
                        fontSize={"sm"}
                    >
                        {property.title}
                    </Text>

                    <Box>
                        {currencyFormat(property.price)}
                    </Box>

                    <Badge fontSize={"xs"} borderRadius='full' px='2' colorScheme='teal' mt={2}>
                        {property.type}
                    </Badge>
                </Box>

                <Box p={3}>
                    <Button fontSize={"sm"} size={'sm'} colorScheme={checkCourse ? "green" : "blue"}>
                        {/* {property.enrolledCourses === undefined ? "Enroll Course" : property.enrolledCourses.includes(property.id) ? "Start Course" : "Enroll Course"} */}
                        {checkCourse ? "Start Course" : "Enroll Course"}
                    </Button>

                </Box>
            </Flex>
        </Box >
    )
}

export default AppCoursesCard
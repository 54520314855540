import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    ButtonGroup,
    Container,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    Avatar,
    Badge,
    Checkbox,
    IconButton,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Image,
} from '@chakra-ui/react'
import { FiSearch, FiEye } from 'react-icons/fi'
import { currencyFormat } from '../../../../Utils/currencyFormat'
import { useNavigate } from 'react-router-dom'
import { getDocInCollection } from '../../../../Api/firebase/getDoc'

function Subscriptions() {
    const [subscriptions, setSubscriptions] = useState([])

    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    })

    const handleGetCourses = async () => {
        const q = await getDocInCollection("/subscriptions");

        console.log(q);
        setSubscriptions(q);
    };

    useEffect(() => {
        handleGetCourses();
    }, []);
    return (
        <Container
            py={{
                base: '4',
                md: '8',
            }}
            px={{
                base: '0',
                md: 8,
            }}
            maxW="container.xl"
        >
            <Box
                bg="bg-surface"
                boxShadow={{
                    base: 'none',
                    md: useColorModeValue('sm', 'sm-dark'),
                }}
                borderRadius={useBreakpointValue({
                    base: 'none',
                    md: 'lg',
                })}
                w={"100%"}
            >
                <Stack spacing="5" w={"100%"}>
                    <Box
                        px={{
                            base: '4',
                            md: '6',
                        }}
                        pt="5"
                    >
                        <Stack
                            direction={{
                                base: 'column',
                                md: 'row',
                            }}
                            justify="space-between"
                        >
                            <Text fontSize="lg" fontWeight="medium">
                                Subscriptions
                            </Text>
                            <InputGroup maxW="xs">
                                <InputLeftElement pointerEvents="none">
                                    <Icon as={FiSearch} color="muted" boxSize="5" />
                                </InputLeftElement>
                                <Input placeholder="Search" />
                            </InputGroup>
                        </Stack>
                    </Box>

                    <Box overflowX="auto" w={"100%"}>
                        <SubscriptionTable data={subscriptions} />
                    </Box>

                    <Box
                        px={{
                            base: '4',
                            md: '6',
                        }}
                        pb="5"
                    >
                        <HStack spacing="3" justify="space-between">
                            {!isMobile && (
                                <Text color="muted" fontSize="sm">
                                    Showing 1 to 5 of 42 results
                                </Text>
                            )}
                            <ButtonGroup
                                spacing="3"
                                justifyContent="space-between"
                                width={{
                                    base: 'full',
                                    md: 'auto',
                                }}
                                variant="secondary"
                            >
                                <Button>Previous</Button>
                                <Button>Next</Button>
                            </ButtonGroup>
                        </HStack>
                    </Box>
                </Stack>
            </Box>
        </Container>
    )
}

const SubscriptionTable = (props) => {
    const data = props.data || [];
    const navigate = useNavigate();
    return (
        <Table {...props}>
            <Thead>
                <Tr>
                    <Th>
                        <Text>Subscription</Text>
                    </Th>
                    <Th>Price</Th>
                    <Th>Durations</Th>
                    <Th>Actions</Th>
                </Tr>
            </Thead>
            <Tbody>
                {data.map((item, index) => (
                    <Tr key={index}>
                        <Td>
                            <Text ml="3" fontWeight="medium">
                                {item.subscriptionName}
                            </Text>

                            <Text ml="3" fontWeight={"hairline"}>
                                {item.subscriptionDescription}
                            </Text>
                        </Td>

                        <Td>
                            <Text color="muted">
                                {currencyFormat(item.subscriptionPrice)}
                            </Text>
                        </Td>

                        <Td>
                            <Text color="muted">
                                {item.subscriptionDuration} Months
                            </Text>
                        </Td>

                        <Td>
                            <HStack spacing="1">
                                <IconButton
                                    icon={<FiEye fontSize="1.25rem" />}
                                    variant="ghost"
                                    aria-label="course detail"
                                    onClick={() => navigate(`/admin/subscriptions/${item.id}`)}
                                />
                            </HStack>
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    )
}

export default Subscriptions
import React, { useReducer } from "react";

let user = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : "";

let role = localStorage.getItem("role")
  ? JSON.parse(localStorage.getItem("role"))
  : "";

let enrolledCourses = localStorage.getItem("enrolledCourses")
  ? JSON.parse(localStorage.getItem("enrolledCourses"))
  : "";

export const initialState = {
  user: "" || user,
  role: "" || role,
  enrolledCourses: "" || enrolledCourses,
  loading: false,
  errorMessage: null,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
      };
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        user: action.payload.user,
        role: action.payload.role,
        enrolledCourses: action.payload.enrolledCourses,
        loading: false,
      };
    case "LOGOUT":
      return {
        ...initialState,
        user: "",
        role: "",
        enrolledCourses: [],
      };

    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

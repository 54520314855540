import { Box, Button, Center, Flex, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

function AppBarAdmin() {
    let navigate = useNavigate();
    return (
        <Flex bg="primary" color="on-primary" w="100%" h="full" justifyContent="space-between" px="4">
            <Box w="15%" h="30%" bgColor="blue.100" color="on-secondary" borderRadius="md" m="2" mt={15} shadow={"md"}>
                <SimpleGrid columns={1} spacing={2} p="2">
                    <Button colorScheme="blue" variant="solid" size="sm" m="2" onClick={() => navigate("/admin/users")}>
                        Users
                    </Button>

                    <Button colorScheme="blue" variant="solid" size="sm" m="2" onClick={() => navigate("/admin/courses")}>
                        Courses
                    </Button>

                    <Button colorScheme="blue" variant="solid" size="sm" m="2" onClick={() => navigate("/admin/subscriptions")}>
                        Subscriptions
                    </Button>
                </SimpleGrid>
            </Box>

            <Box w="85%" color="on-secondary" m="2">
                <Outlet />
            </Box>
        </Flex>
    )
}

export default AppBarAdmin
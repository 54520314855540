import { Box, Button, Divider, FormControl, FormLabel, Heading, HStack, Input, SimpleGrid, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { getCollectionCourseForMultipleSelect, getSpecifiedDoc } from '../../../../Api/firebase/getDoc';
import { db } from '../../../../Configs/firebase';
import { doc, updateDoc } from 'firebase/firestore';




function AppBarAdminEditUsers() {
    const param = useParams();
    const toast = useToast();
    const navigate = useNavigate();

    const [user, setUser] = useState({});
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [role, setRole] = useState("");
    const [enrolledCourses, setEnrolledCourses] = useState([]);
    const [options, setOptions] = useState([]);

    const findUser = async () => {
        const x = await getSpecifiedDoc("/user", param.userId);
        setUser(x);
        setEnrolledCourses(x.enrolledCourses);
    }

    const getCourses = async () => {
        const x = await getCollectionCourseForMultipleSelect("/courses");
        setOptions(x);
    }

    const handleUserNameChange = (event) => {
        setUserName(event.target.value);
    };
    
    const handleUserEmailChange = (event) => {
        setUserEmail(event.target.value);
    };

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    const handleEnrolledCourses = (event) => {
        setEnrolledCourses(event);
    }

    const handleSubmit = async () => {
        try {
            const data = {
                userName: userName ? userName : user.userName,
                userEmail: userEmail ? userEmail : user.userEmail,
                role: role ? role : user.role,
                enrolledCourses: enrolledCourses
            }
            
            const userRef = doc(db, "user", param.userId);
            await updateDoc(userRef, data, { merge: true })
            .then(response => {
                toast({
                    title: "Success",
                    description: "New user added",
                    status: "success",
                    duration: 9000,
                    position: "top",
                    isClosable: true,
                });
                navigate("/admin/users");
              }).catch(error =>{
                console.log(error.message)
              })
        } catch (error) {
            console.log(error, "error");
            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 9000,
                position: "top",
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        findUser();
        getCourses();
    }, []);

    return (
        <Box as="nav" h={"80px"} pt={1}>
            <Box maxW="1440" p={"5"}>
                <Heading as="h3" size="md" color="on-accent">
                    Edit Users
                </Heading>
            </Box>

            <Divider />

            <SimpleGrid columns={2} spacing={3} p={5}>
                <Box>
                    <FormControl isRequired>
                        <FormLabel htmlFor="karyawan">Full Name</FormLabel>
                        <Input
                            id="userName"
                            type="text"
                            value={userName || user.userName}
                            onChange={handleUserNameChange}
                        />
                    </FormControl>
                </Box>

                <Box>
                    <FormControl isRequired>
                        <FormLabel htmlFor="karyawan">Email</FormLabel>
                        <Input
                            id="userEmail"
                            type="email"
                            value={userEmail || user.userEmail}
                            onChange={handleUserEmailChange}
                        />
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <FormLabel htmlFor="karyawan">Courses</FormLabel>
                        <Select 
                            id="enrolledCourses"
                            value={enrolledCourses}
                            isMulti 
                            options={options}
                            onChange={handleEnrolledCourses}
                        />
                    </FormControl>
                </Box>  
                <Box>
                    <FormControl isRequired>
                        <FormLabel htmlFor="karyawan">Role</FormLabel>
                        <Input
                            id="role"
                            type="text"
                            value={role || user.role}
                            onChange={handleRoleChange}
                        />
                    </FormControl>
                </Box>
            </SimpleGrid>

            <HStack spacing={4} p={5} justifyContent={"flex-end"}>
                <Button colorScheme={"blue"} onClick={() => handleSubmit()}>
                    Edit User
                </Button>
            </HStack>
        </Box >
    )
}

export default AppBarAdminEditUsers